<template>
  <div>
    <div class="d-flex justify-content-end mb-2">
      <b-button :disabled="isLoading" @click="handleCreatesSaling" variant="primary">Tạo phiếu xuất</b-button>
    </div>
    <b-card>
      <div class="row">
        <div class="col-md-4 d-flex">
          <span>Tên đơn hàng:</span>
          <span class="cs-data">{{ prescriptionData.name }}</span>
        </div>
        <div class="col-md-4 d-flex">
          <span>Bác sĩ kê đơn:</span>
          <span class="cs-data">{{ prescriptionData.prescribed_doctor }}</span>
        </div>
        <div class="col-md-4 d-flex">
          <span>Nơi chỉ định:</span>
          <span class="cs-data">{{ prescriptionData.prescribed_clinic }}</span>
        </div>
      </div>
      <div v-if="prescriptionData.person_info" class="row mt-2">
        <div class="col-md-4 d-flex">
          <span>Tên Khách hàng:</span>
          <span class="cs-data">{{ prescriptionData.person_info.name }}</span>
        </div>
        <div class="col-md-3 d-flex">
          <span>Giới tính:</span>
          <span class="cs-data">{{ prescriptionData.person_info.gender===1?"Nam":'Nữ' }}</span>
        </div>
        <div class="col-md-2 d-flex">
          <span>Tuổi:</span>
          <span class="cs-data">{{ getAge(prescriptionData.person_info.birthday) }}</span>
        </div>
        <div class="col-md-3 d-flex">
          <span>Ngày kê đơn:</span>
          <span class="cs-data">{{ formatDate(prescriptionData.date) }}</span>
        </div>
      </div>
    </b-card>
    <b-card class="p-0">
      <b-overlay
        :show="isLoading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          class="position-relative"
          ref="refPrescriptionTable"
          :items="medicineList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          sort-by="created_at"
          show-empty
          empty-text="Không có dữ liệu hiển thị"
        >
          <template #cell(medicine_code)="data">
            <span
              v-if="data.item.pharma_product"
              class="text-capitalize"
            >{{ `#${data.item.pharma_product.product_code}` }}</span>
          </template>
          <template #cell(medicine_name)="data">
            <span
              v-if="data.item.pharma_product"
              class="text-capitalize"
            >{{data.item.pharma_product.name}}</span>
          </template>
          <template #cell(unit)="data">
            <span class="text-capitalize">{{ getUnit(data.item )}}</span>
          </template>
          <template #cell(amount)="data">
            <span class="text-capitalize">{{ (data.item.amount )}}</span>
          </template>
          <template #cell(price)="data">
            <span
              v-if="data.item.pharma_product"
              class="text-capitalize"
            >{{ formatPrice(data.item.pharma_product.price_out )}}</span>
          </template>
          <template #cell(vat)="data">
            <span
              v-if="data.item.pharma_product"
              class="text-capitalize"
            >{{ `${data.item.pharma_product.vat_out}%` }}</span>
          </template>
          <template #cell(expired_date)="data">
            <span
              v-if="data.item.pharma_product"
              class="text-capitalize"
            >{{ formatExpiredDate(data.item.pharma_product) }}</span>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appUtils from "../../utils/appUtils.js";
import { SALE_TYPE } from "@/utils/constant";

const formaterCurrency = new Intl.NumberFormat("en-US");

const COLUMNS = [
  { key: "medicine_code", label: "Mã thuốc", sortable: false },
  { key: "medicine_name", sortable: false, label: "Tên thuốc" },
  { key: "amount", sortable: false, label: "Số lượng" },
  { key: "unit", sortable: false, label: "Đơn vị" },
  { key: "price", sortable: false, label: "Giá mua" },
  { key: "vat", sortable: false, label: "VAT" },
  { key: "expired_date", sortable: false, label: "Hạn sử dụng" }
];
export default {
  name: "PrescriptionWaiting",
  data() {
    return {
      tableColumns: [...COLUMNS],
      isLoading: false,
      medicineList: [],
      prescriptionData: {},
      formaterCurrency
    };
  },
  created() {
    this.fetchPrescription();
  },
  computed: {},
  methods: {
    async fetchPrescription() {
      if (!this.$route.params.id) return;
      try {
        this.isLoading = true;

        const response = await this.$store.dispatch(
          "phamarProductInventory/getPrescriptionWaiting",
          this.$route.params.id
        );
        this.medicineList = response.data?.person_medicine || [];
        this.prescriptionData = response.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      return window.moment(date).format("DD/MM/YYYY");
    },
    getUnit(data) {
      return data?.pharma_product?.unit_info?.name || "";
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    formatExpiredDate(data) {
      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    },
    getAge(birthday) {
      return (
        birthday &&
        (window.moment().diff(birthday, "years", false)
          ? `${window.moment().diff(birthday, "years", false)} tuổi`
          : "")
      );
    },
    totalPrice() {
      if (this.medicineList?.length > 0) {
        const arrRowsSum = this.medicineList.map(
          x =>
            this.formaterCurrencyToNumber(x.pharma_product.price_out) * x.amount
        );
        const total = arrRowsSum.reduce((a, b) => a + b, 0);
        return this.formaterCurrency.format(total);
      } else {
        return 0;
      }
    },
    handleCreatesSaling() {
      if (!this.prescriptionData?.id) return;

      this.$router.push({
        name: "AddSaling",
        query: { prescriptionId: this.prescriptionData.id }
      });
    },
    createSaling() {
      try {
        this.isLoading = true;
        const payload = {
          total_amount: this.formaterCurrencyToNumber(this.totalPrice()),
          sale_type: SALE_TYPE.patient,
          prescription_id: this.prescriptionData.id,
          pharma_inventory_id: this.prescriptionData.pharma_inventory_id,
          detail_sale: this.medicineList.map(item => ({
            product_id: item.pharma_product_id,
            qty: parseInt(item.amount),
            sale_price: this.formaterCurrencyToNumber(
              item.pharma_product.price_out
            )
          }))
        };

        this.$store.dispatch("saling/addSaling", payload).then(res => {
          this.$router.push({
            name: "SalingDetail",
            params: {
              id: res.data?.id
            }
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Tạo phiếu xuất thành công",
              icon: "CheckCircleIcon",
              variant: "success"
            }
          });
        });
      } catch (error) {
        this.isLoading = false;

        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Lỗi khi tạo phiếu xuất",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      } finally {
      }
    },
    formaterCurrencyToNumber(value) {
      return Number(value?.toString().replace(/[^0-9.-]+/g, ""));
    }
  }
};
</script>


<style lang="scss" scoped>
.cs-data {
  display: block;
  margin-left: 4px;
  font-weight: 600;
}
</style>